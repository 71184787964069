"use client";

import typography from "components/typography";
import { analytics } from "lib/segment";
import { useSearchParams } from "next/navigation";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { segmentTrackBooking } from "utils/analytics";
import { cn } from "utils/functions";
import { isValidEmail } from "utils/emailValidation";
import { mixpanelAnalytics as mixpanel } from "lib/mixpanel";

declare global {
  interface Window {
    ChiliPiper?: any;
  }
}

interface ChiliPiperResponse {
  success: boolean;
  error?: string;
}

interface ContactFormProps {
  formHeading?: string;
  formCta?: string;
  formName?: string;
  formAccentColor?: string;
}

// Add this constant at the top of the file, outside the component
const EUROPEAN_COUNTRIES = [
  // EU Countries
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "HR", // Croatia
  "CY", // Cyprus
  "CZ", // Czech Republic
  "DK", // Denmark
  "EE", // Estonia
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GR", // Greece
  "HU", // Hungary
  "IE", // Ireland
  "IT", // Italy
  "LV", // Latvia
  "LT", // Lithuania
  "LU", // Luxembourg
  "MT", // Malta
  "NL", // Netherlands
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain
  "SE", // Sweden

  // Non-EU European Countries
  "AL", // Albania
  "AD", // Andorra
  "AM", // Armenia
  "AZ", // Azerbaijan
  "BY", // Belarus
  "BA", // Bosnia and Herzegovina
  "CH", // Switzerland
  "GB", // United Kingdom (Great Britain)
  "UK", // United Kingdom (alternate code)
  "GE", // Georgia
  "IS", // Iceland
  "XK", // Kosovo
  "LI", // Liechtenstein
  "MD", // Moldova
  "MC", // Monaco
  "ME", // Montenegro
  "MK", // North Macedonia
  "NO", // Norway
  "RU", // Russia
  "SM", // San Marino
  "RS", // Serbia
  "TR", // Turkey
  "UA", // Ukraine
  "VA", // Vatican City
];

const ContactForm: FC<ContactFormProps> = ({ formHeading, formCta, formName, formAccentColor }) => {
  const [country, setCountry] = useState<string>("");
  const [countryRegion, setCountryRegion] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [info, setInfo] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [companySize, setCompanySize] = useState<string>("");
  const [showThankYou, setShowThankYou] = useState(false);
  const [showQualificationThankYou, setShowQualificationThankYou] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState<string>(info);
  const [showMeetingForm, setShowMeetingForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isScheduling, setIsScheduling] = useState(false);

  const validEmail = isValidEmail(email);

  const searchParams = useSearchParams();

  const formDataRef = useRef({
    form: `${formName ?? "Enterprise"} Contact Form`,
    first_name: firstName,
    last_name: lastName,
    work_email: email,
    company: company,
    lead_source: searchParams.get("lead_source"),
    company_size: companySize,
    info: "",
    country: country,
    countryRegion: countryRegion,
    utm_source: searchParams.get("utm_source"),
    utm_medium: searchParams.get("utm_medium"),
    utm_campaign: searchParams.get("utm_campaign"),
  });

  // First, create a loading state ref to track the actual loading state
  const isLoadingRef = useRef(false);

  useEffect(() => {
    fetch("/api/get-geo-and-ip")
      .then((response) => response.json())
      .then((data: any) => {
        setCountry(data.geo.country?.toUpperCase());
        setCountryRegion(data.geo.region?.toUpperCase());
      });
  }, []);

  function trackBookingActionsHandler(event: MessageEvent) {
    segmentTrackBooking(event.data["action"], formDataRef.current);
  }

  useEffect(() => {
    window.addEventListener("message", trackBookingActionsHandler, false);

    return () => {
      window.removeEventListener("message", trackBookingActionsHandler, false);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://appsmith.chilipiper.com/concierge-js/cjs/concierge.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script on component unmount
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="rounded-lg bg-primary-dark-50 p-sm xl:p-md">
      {!showThankYou && !showQualificationThankYou ? (
        <>
          <h2 className={typography.heading.innerSubheading}>
            {formHeading ?? "Book a demo with our team to get started with Appsmith Enterprise"}
          </h2>
          <form
            id="wf-form-Stay-updated-on-the-launch-Form"
            name="wf-form-Stay-updated-on-the-launch-Form"
            data-name="Stay updated on the launch Form"
            aria-label="Stay updated on the launch Form"
            className="mt-md grid grid-cols-1 gap-[20px] text-base sm:grid-cols-2"
            onSubmit={async (e) => {
              e.preventDefault();

              if (isLoadingRef.current) return; // Prevent double submission

              try {
                isLoadingRef.current = true;
                setIsSubmitting(true);

                // Analytics tracking
                await Promise.all([
                  analytics?.identify(email, { name: `${firstName} ${lastName}`, email: email }),
                  analytics?.alias(email),
                  analytics?.track("Submitted Form", { ...formDataRef.current, userId: email }),
                  mixpanel?.identify(email),
                  mixpanel?.alias(email),
                  mixpanel?.people.set_once({ first_conversion_name: formDataRef.current.form }),
                ]);

                const employeeCount = parseInt(companySize.split("-")[0]);

                // Implement v2 Flow logic - Step 2
                const isQualifiedForDirectBooking =
                  // US, Canada, or Europe with 100+ employees
                  (["US", "CA"].includes(country || "") || EUROPEAN_COUNTRIES.includes(country || "")) &&
                  employeeCount >= 100;

                const needsAdditionalQualification =
                  // Europe/NA with less than 100 employees OR outside NA/Europe with less than 500 employees
                  ((["US", "CA"].includes(country || "") || EUROPEAN_COUNTRIES.includes(country || "")) &&
                    employeeCount < 100) ||
                  (!["US", "CA"].includes(country || "") &&
                    !EUROPEAN_COUNTRIES.includes(country || "") &&
                    employeeCount < 500);

                if (needsAdditionalQualification) {
                  // Step 3a: Route for additional qualification
                  await fetch("https://appsmith.chilipiper.com/concierge-router/inbound_router/rest", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      form: {
                        PersonFirstName: firstName,
                        PersonLastName: lastName,
                        PersonEmail: email,
                        "3f9d4143-a29c-43d3-8041-85557ea7f98d": country,
                        "807d59e2-a6f4-4371-8ffd-936c11a97175": info,
                        "3f438227-9cdd-4477-8e50-77b28b59c571": formDataRef.current.lead_source ?? "Website",
                        "27cb1a9d-88a8-436a-bf0e-21004b516e74": countryRegion,
                        "d9dbcb3e-2953-4b27-87d5-35da7d18222a": "Additional Qualification",
                        "e27eaa1b-e30f-48e4-891f-1d398f7dda29": companySize,
                      },
                      options: {
                        // Required for creating the lead in Salesforce according to new spec
                        leadSource: formDataRef.current.lead_source ?? "Website",
                        leadOwner: "Eric",
                        leadSourceDetail: "Unqualified meeting or demo request",
                      },
                    }),
                  })
                    .then((response) => {
                      if (response.ok) {
                        setShowQualificationThankYou(true);
                      } else {
                        console.error("Error submitting form:", response);
                      }
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                    });
                } else {
                  // Step 3b: Direct meeting booking for qualified leads
                  window.ChiliPiper?.submit("appsmith", "inbound_router", {
                    lead: {
                      form: formDataRef.current.form ?? "Enterprise Contact Form",
                      Firstname: firstName,
                      Lastname: lastName,
                      email,
                      company_name: company,
                      enterprise_company_size: companySize,
                      Info: info,
                      lead_source: formDataRef.current.lead_source ?? "Website",
                      country,
                      countryRegion,
                      utm_source: formDataRef.current.utm_source ?? "",
                      utm_medium: formDataRef.current.utm_medium ?? "",
                      utm_campaign: formDataRef.current.utm_campaign ?? "",
                    },
                    router: "inbound_router",
                    domain: "appsmith",
                    onSuccess: function (data: any) {
                      setShowThankYou(true);
                    },
                    onError: function (error: any) {
                      console.error("Chili Piper Error:", error);
                    },
                  });
                }
              } catch (error) {
                console.error("Error submitting form:", error);
              } finally {
                isLoadingRef.current = false;
                setIsSubmitting(false);
              }
            }}
          >
            <div>
              <label htmlFor="first-name" className={typography.inputLabel}>
                First name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
              </label>
              <div className="mt-4xs">
                <input
                  type="text"
                  maxLength={256}
                  name="Firstname"
                  id="Firstname"
                  value={firstName}
                  onChange={(e) => {
                    formDataRef.current.first_name = e.target.value;
                    setFirstName(e.target.value);
                  }}
                  required
                  autoComplete="given-name"
                  className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
                />
              </div>
            </div>
            <div>
              <label htmlFor="last-name" className={typography.inputLabel}>
                Last name <span className="font-light text-tertiary-red-500 opacity-60">*</span>
              </label>
              <div className="mt-4xs">
                <input
                  type="text"
                  name="Lastname"
                  id="Lastname"
                  value={lastName}
                  onChange={(e) => {
                    formDataRef.current.last_name = e.target.value;
                    setLastName(e.target.value);
                  }}
                  required
                  autoComplete="family-name"
                  className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className={typography.inputLabel}>
                Work email <span className="font-light text-tertiary-red-500 opacity-60">*</span>
              </label>
              <div className="mt-4xs">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    formDataRef.current.work_email = e.target.value;
                    setEmail(e.target.value);
                  }}
                  onBlur={() => {
                    if (validEmail) {
                      analytics?.identify(email, { email: email });
                    }
                  }}
                  required
                  autoComplete="email"
                  className={cn(
                    "block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]",
                    !validEmail ? "border-tertiary-red-500" : "",
                  )}
                />
                {!validEmail ? (
                  <p className="mt-[5px] text-sm text-tertiary-red-500">Please enter a valid work email address.</p>
                ) : null}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="company_name" className={typography.inputLabel}>
                Company <span className="font-light text-tertiary-red-500 opacity-60">*</span>
              </label>
              <div className="mt-4xs">
                <input
                  type="text"
                  name="company_name"
                  id="company_name"
                  value={company}
                  onChange={(e) => {
                    formDataRef.current.company = e.target.value;
                    setCompany(e.target.value);
                  }}
                  required
                  autoComplete="organization"
                  className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="company_size" className={typography.inputLabel}>
                What is your company size? <span className="font-light text-tertiary-red-500 opacity-60">*</span>
              </label>
              <div className="mt-4xs">
                <select
                  name="Company-Size"
                  id="Company-Size"
                  required
                  onChange={(e) => {
                    formDataRef.current.company_size = e.target.value;
                    setCompanySize(e.target.value);
                  }}
                  value={companySize}
                  className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
                >
                  <option disabled value="">
                    Please select
                  </option>
                  <option value="1-24">1-24 Employees</option>
                  <option value="25-99">25-99 Employees</option>
                  <option value="100-499">100-499 Employees</option>
                  <option value="500-1499">500-1,499 Employees</option>
                  <option value="2000-4999">2,000-4,999 Employees</option>
                  <option value="5000+">5,000+ Employees</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex justify-between">
                <label htmlFor="Info" className={typography.inputLabel}>
                  Tell us a little bit about your project.
                </label>
                <span id="Info-description" className=" hidden text-xs sm:inline-block sm:text-sm">
                  Max. 500 characters
                </span>
              </div>
              <div className="mt-4xs">
                <textarea
                  id="Info"
                  name="Info"
                  aria-describedby="Info"
                  rows={4}
                  className="block w-full rounded-md border border-primary-dark-800/20 px-2xs py-[5px]"
                  value={info}
                  onChange={(e) => {
                    formDataRef.current.info = e.target.value;
                    setInfo(e.target.value);
                  }}
                />
                <span id="Info-description" className=" inline-block text-xs sm:hidden sm:text-sm">
                  Max. 500 characters
                </span>
              </div>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                disabled={!validEmail || isSubmitting}
                className={cn(
                  "group flex h-[50px] w-full items-center justify-center whitespace-nowrap rounded px-sm py-xs font-medium leading-none",
                  formAccentColor === "tangerine-400"
                    ? "bg-gradient-to-b from-secondary-brand-500 to-secondary-brand-500/80 text-primary-neutral-50 hover:from-secondary-brand-500/30 hover:to-secondary-brand-500/20 hover:text-secondary-brand-500"
                    : "bg-gradient-to-b from-primary-light-500 to-primary-light-550 text-primary-neutral-50 hover:from-primary-light-500/20 hover:to-primary-light-550/20 hover:text-primary-light-500 dark:hover:text-primary-neutral-50",
                  "transition-colors",
                  !validEmail || isSubmitting ? "cursor-not-allowed opacity-80" : "",
                )}
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="10" viewBox="0 0 120 30" fill="#fff">
                      <circle cx="15" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                        <animate
                          attributeName="r"
                          from="9"
                          to="9"
                          begin="0s"
                          dur="0.8s"
                          values="9;15;9"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="0.5"
                          to="0.5"
                          begin="0s"
                          dur="0.8s"
                          values=".5;1;.5"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="105" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                    </svg>
                  </div>
                ) : (
                  (formCta ?? "Get a demo")
                )}
              </button>
              <input
                type="hidden"
                name="lead_source"
                id="lead_source"
                value={formDataRef.current.lead_source ?? "Website"}
              />
              <input type="hidden" name="country" value={country} />
              <input type="hidden" name="countryRegion" value={countryRegion} />
            </div>
          </form>
          <div className="w-form-fail hidden">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
          <div aria-hidden="true" id="required-description" className="mt-sm text-sm font-light opacity-60">
            <span className="font-light text-tertiary-red-500 opacity-60">*</span> Required
          </div>
        </>
      ) : showQualificationThankYou ? (
        <div className="success-message py-lg text-center">
          <h2 className={typography.heading.innerSubheading}>Thank you for your interest in Appsmith</h2>
          <p className="mt-sm">
            One of our team members will be in touch shortly to schedule a meeting and demo of our open-source low-code
            platform. You can also check out the platform yourself by signing up for our free edition.
          </p>

          <a
            href="https://app.appsmith.com/signup"
            className={cn(
              "mt-md inline-block rounded px-sm py-xs font-medium",
              "bg-gradient-to-b from-primary-light-500 to-primary-light-550 text-primary-neutral-50",
              "hover:from-primary-light-500/20 hover:to-primary-light-550/20 hover:text-primary-light-500",
            )}
          >
            Try our free edition
          </a>
        </div>
      ) : (
        <div className="success-message py-lg text-center">
          <h2 className={typography.heading.innerSubheading}>Thank you for your submission!</h2>
          <p className="mt-sm">Our team will be in touch with you shortly.</p>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
